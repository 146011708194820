import { BsFunnelFill } from "react-icons/bs"
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard"
import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList"

function OrderSettings() {
    return (
        <NavCardList numOfCards={'four'}>
            <NavCard cardName={'Order Sources'} navCardLink={'/orderSources'} ><BsFunnelFill /></NavCard>
        </NavCardList>
    )
}

export default OrderSettings